import { FormStructureScheme } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import * as React from "react";
import * as environment from "environment";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { countryField, departmentsField, nationalityField, sexField, statutField } from "./profileElements";
import { checkMinBirthDateCreator } from "@energylab/shared-components/utils/checkMinBirthDate";

export const profileStructure: FormStructureScheme = {
    type: "form",
    formElements: [
        {
            type: "row",
            property: "names",
            formElements: [
                {
                    title: "auth.fields.givenName",
                    placeholder: "auth.fields.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "auth.fields.familyName",
                    placeholder: "auth.fields.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                }
            ]
        },
        {
            title: "auth.fields.birthDate",
            type: "date",
            property: "birthDate",
            required: true,
            disabled: false,
            validationRules: [
                {
                    validatorBuilder: checkMinBirthDateCreator(environment.minYearOld)
                }
            ]
        },
        sexField,
        countryField,
        nationalityField,
        {
            title: "auth.fields.email",
            placeholder: "auth.fields.email",
            type: "text",
            property: "email",
            required: true
        },
        departmentsField,
        statutField,
        {
            title: "form.language",
            type: "select",
            options: (environment.languages || []).map(locale => (
                {
                    title: <FormatMessage id={`locale.${locale}`} />,
                    value: locale
                }
            )),
            property: "locale",
            required: true
        }
    ]
};
