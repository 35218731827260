import { SeoConfig } from "@energylab/shared-components/constants/config/seoConfig";

export const seo: SeoConfig = {
    pageTitle: "FIT@Arvesta",
    title: {
        nl: "FIT@Arvesta",
        fr: "FIT@Arvesta",
        en: "FIT@Arvesta"
    },
    description: {
        nl: "",
        fr: "",
        en: ""
    },
    image: "",
    site: ""
};
