import { RedirectType } from "@energylab/shared-components/routes/routes";
import { lazy } from "react";

const DashboardOverviewContainer = lazy(() => import("@energylab/shared-components/modules/dashboard/dashboardOverview/dashboardOverviewContainer"));
const ContentCardOverviewContainer = lazy(() => import("@energylab/shared-components/modules/benefits/contentCardOverview/contentCardOverviewContainer"));

export const ROUTES = [
    {
        locales: {
            nl: "home"
        },
        element: <DashboardOverviewContainer />,
        path: "dashboard"
    },
    {
        path: "webshop",
        element: <ContentCardOverviewContainer introKey="benefits" metaDataKey="benefits" contentType="benefit" />
    }
]

export const REDIRECTS: RedirectType[] = [];
