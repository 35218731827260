import { FormStructureScheme } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import { compareToFirstPasswordCreator } from "@energylab/shared-components/utils/compareToFirstPassword";
import { getPasswordRules } from "@energylab/shared-components/utils/passwordRule";
import { password, minYearOld } from "environment";
import * as React from "react";
import * as environment from "environment";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { countryField, departmentsField, nationalityField, sexField, statutField } from "./profileElements";
import { checkMinBirthDateCreator } from "@energylab/shared-components/utils/checkMinBirthDate";

export const registerStructure: FormStructureScheme = {
    type: "steps",
    steps: [
        {
            title: "register.profilePoliciesStepTitle",
            status: "current",
            icon: "",
            formElements: []
        },
        {
            title: "register.basicStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.email",
                    type: "text",
                    property: "email",
                    required: true,
                    validationRules: [
                        { type: "email", message: "auth.error.invalidEmail" }
                    ]
                },
                {
                    title: "form.password",
                    type: "password",
                    property: "password",
                    validationRules: getPasswordRules(password)
                },
                {
                    title: "register.passwordInfo",
                    type: "p",
                    property: "passwordInfo",
                    hide: true
                },
                {
                    title: "form.confirmPassword",
                    type: "password",
                    property: "passwordConfirm",
                    validationRules: [
                        {
                            validatorBuilder: compareToFirstPasswordCreator,
                            message: <FormatMessage id="auth.error.notEqualPassword" />
                        }
                    ],
                    required: true
                }
            ]
        },

        {
            title: "register.profileStepTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "form.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                },
                sexField,
                countryField,
                nationalityField,
                {
                    title: "form.birthDate",
                    type: "date",
                    property: "birthDate",
                    required: true,
                    validationRules: [
                        {
                            validatorBuilder: checkMinBirthDateCreator(minYearOld)
                        }
                    ]
                },
                departmentsField,
                statutField,
                {
                    title: "form.language",
                    type: "select",
                    options: (environment.languages || []).map(locale => (
                        {
                            title: <FormatMessage id={`locale.${locale}`} />,
                            value: locale
                        }
                    )),
                    property: "locale",
                    required: true
                },
            ]
        },
        {
            title: "register.profileStepExtraTitle",
            status: "current",
            icon: "",
            formElements: [
                {
                    title: "form.avatar",
                    type: "uploadAvatar",
                    property: "avatar"
                },
                {
                    title: "form.showActivities",
                    type: "checkbox",
                    property: "showActivities"
                }
            ]
        }
    ]
};
