import { getRoutes } from "@energylab/shared-components/routes";
import { RouteConfigType } from "@energylab/shared-components/routes/routes";
import { ROUTES } from "../../routes";

const locales = ["nl", "fr"];
const defaultLocale = "nl";

export const routeConfig: RouteConfigType = {
    locales,
    defaultLocale,
    routes: {
        private: getRoutes(ROUTES)
    },
    redirects: [],
    hideNavigationWhenLoggedOut: true
};
