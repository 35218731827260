// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    box-sizing: border-box;
}

body,
html,
#app-container {
    height: 100%;
}

.breadText {
    font-size: 15px;
}

.ant-btn {
    font-size: 14px;
}

.ant-menu-item > a{
    font-size: 14px;
}

.usernav .ant-dropdown-link span {
    font-size: 14px;
}

.userNavMenu .ant-dropdown-menu-item, .userNavMenu .ant-dropdown-menu-item span {
    font-size: 14px;
}

.ant-form-item-label label, .ant-form-item-label label span, .ant-form label {
    font-size: 15px !important;
}

.ant-input {
    font-size: 15px !important;
}

.ant-table-content {
    font-size: 15px;
}

.ant-form-explain {
    font-size: 15px;
}

.ant-tabs-nav .ant-tabs-tab, .ant-tabs-nav .ant-tabs-tab:hover, .ant-tabs-nav .ant-tabs-tab-active, .authSubmenu a p span {
    font-size: 15px !important;
}`, "",{"version":3,"sources":["webpack://./src/style/global.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;;;IAGI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["* {\n    box-sizing: border-box;\n}\n\nbody,\nhtml,\n#app-container {\n    height: 100%;\n}\n\n.breadText {\n    font-size: 15px;\n}\n\n.ant-btn {\n    font-size: 14px;\n}\n\n.ant-menu-item > a{\n    font-size: 14px;\n}\n\n.usernav .ant-dropdown-link span {\n    font-size: 14px;\n}\n\n.userNavMenu .ant-dropdown-menu-item, .userNavMenu .ant-dropdown-menu-item span {\n    font-size: 14px;\n}\n\n.ant-form-item-label label, .ant-form-item-label label span, .ant-form label {\n    font-size: 15px !important;\n}\n\n.ant-input {\n    font-size: 15px !important;\n}\n\n.ant-table-content {\n    font-size: 15px;\n}\n\n.ant-form-explain {\n    font-size: 15px;\n}\n\n.ant-tabs-nav .ant-tabs-tab, .ant-tabs-nav .ant-tabs-tab:hover, .ant-tabs-nav .ant-tabs-tab-active, .authSubmenu a p span {\n    font-size: 15px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
