import * as React from "react";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { SEX } from "@energylab/shared-components/utils/sex";
import { nationalities } from "@energylab/shared-components/constants/nationalities"
import { departments } from "./departments";

export const sexField = {
    title: "auth.fields.sex",
    type: "radio",
    options: [
        { title: <FormatMessage id="male" />, value: SEX.male },
        { title: <FormatMessage id="female" />, value: SEX.female },
        { title: <FormatMessage id="other" />, value: SEX.other }
    ],
    property: "sex",
    required: true
};

const countries = [
    { code: "BEL", name: "Belgium" },
    { code: "NLD", name: "Netherlands" },
    { code: "FRA", name: "France" },
    { code: "DEU", name: "Germany" },
    { code: "other", name: "Other" }
];

export const countryField = {
    title: "auth.fields.country",
    type: "select",
    options: countries.map(country => ({ title: <FormatMessage id={`country.${country.code}`} />, value: country.code })),
    hide: false,
    property: "country",
    required: true
};

export const departmentsField = {
    title: "form.department",
    type: "cascader",
    options: departments,
    property: "department",
    required: true
};

const STATUTES = ["bediende","logistiek/operations/technisch-medewerker", "winkelmedewerker", "externe-medewerker", "student"]

export const statutField = {
    title: "auth.fields.statut",
    type: "select",
    options: STATUTES.map(statut => ({ title: <FormatMessage id={`status.option.${statut}`} />, value: statut })),
    hide: false,
    property: "statut",
    required: true
};

export const nationalityField = {
    title: "auth.fields.nationality",
    type: "select",
    options: nationalities.map(nat => (
        {
            title: <FormatMessage id={`nationality.${nat.code}`} />,
            value: nat.code
        }
    )),
    hide: false,
    property: "nationality",
    required: true
}
