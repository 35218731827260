import { leaderboardConfig } from "@energylab/shared-components/constants/config/leaderboardConfig";
import { ConfigReducerState } from "@energylab/shared-components/redux/reducers/configReducer";
import { getBrowserLocale } from "@energylab/shared-components/utils/browserLocale";
import { ambassadorSettingsConfig } from "./constants/config/ambassadorSettingsConfig";
import { articleTagsConfig } from "./constants/config/articleTagsConfig";
import { auth } from "./constants/config/authConfig";
import { challengeConfig } from "./constants/config/challengeConfig";
import { coinsConfig } from "./constants/config/coinsConfig";
import { commentsConfig } from "./constants/config/commentsConfig";
import { contactConfig } from "./constants/config/contactConfig";
import { contentConfig } from "./constants/config/contentConfig";
import { customDashboardPlacesConfig } from "./constants/config/dashboardPlacesConfig";
import { dashboardSettingsConfig } from "./constants/config/dashboardSettingsConfig";
import { departmentsConfig } from "./constants/config/departmentsConfig";
import { eventsConfig } from "./constants/config/eventsConfig";
import { footerConfig } from "./constants/config/footerConfig";
import { galleryConfig } from "./constants/config/galleryConfig";
import { likesConfig } from "./constants/config/likesConfig";
import { logoutConfig } from "./constants/config/logoutConfig";
import { newUsersConfig } from "./constants/config/newUsersConfig";
import { nicknameConfig } from "./constants/config/nicknameConfig";
import { notificationsConfig } from "./constants/config/notificationsConfig";
import { profileConfig } from "./constants/config/profileConfig";
import { providers } from "./constants/config/providersConfig";
import { registerConfig } from "./constants/config/registerConfig";
import { resetPasswordConfig } from "./constants/config/resetPasswordConfig";
import { routeConfig } from "./constants/config/routeConfig";
import { seo } from "./constants/config/seoConfig";
import { socialConfig } from "./constants/config/socialConfig";
import { socialShareConfig } from "./constants/config/socialShareConfig";
import { webshopConfig } from "./constants/config/webshopConfig";
import { whiteListSignUpConfig } from "./constants/config/whiteListSignUpConfig";
import { navbarConfig } from "./constants/config/navbarConfig";
import { tourConfig } from "./constants/config/tourConfig";

export const config: ConfigReducerState = {
    navbarConfig,
    profileConfig,
    password: {
        requireOldPassword: false,
        minLength: 8,
        requireLetter: true,
        requireUpperCase: true,
        requireLowerCase: true,
        requireNumber: true,
        requireSpecialCharacter: false,
        allowedSpecialCharacters: [],
        lockOut: {
            enabled: false,
            time: 0,
            attempts: 10
        }
    },
    registerConfig,
    hideNationality: true,
    hideIntersexual: true,
    enableCountry: false,
    departmentsConfig,
    dashboardPlacesConfig: customDashboardPlacesConfig,
    hideSocialLogin: true,
    hideSocialLinks: true,
    dashboardTopPadding: "30px",
    socialConfig,
    ambassadorImage: "",
    auth,
    commentsConfig,
    eventsConfig,
    contentConfig,
    contactConfig,
    companyLogo: "https://res.cloudinary.com/energylab/image/upload/w_200/v1578919471/Arvesta/logo_Arvesta_witteachtergrond_zondertagline2.png",
    companyName: "Arvesta",
    defaultTimezone: "Europe/Brussels",
    defaultCountry: "Belgium",
    defaultNationality: "BEL",
    coinsConfig,
    socialTags: [],
    likesConfig,
    webshopConfig,
    notificationsConfig,
    providers,
    jumbotronImage: "",
    localeDateFormat: "DD/MM/YYYY",
    routeConfig,
    nationalities: [],
    selectedLanguage: getBrowserLocale(routeConfig.defaultLocale, routeConfig.locales),
    seo,
    uploadPreset: "arvesta",
    newUsersConfig,
    articleTagsConfig,
    nicknameConfig,
    footerConfig,
    challengeConfig,
    galleryConfig,
    logoutConfig,
    resetPasswordConfig,
    leaderboardConfig,
    socialShareConfig,
    whiteListSignUpConfig,
    ambassadorSettingsConfig,
    dashboardSettingsConfig,
    tourConfig,
    mustAcceptPolicies: ["privacy", "disclaimer", "cookiePolicy"]
};
