import { NavbarConfig } from "@energylab/shared-components/constants/config/navbarConfig";

export const navbarConfig: NavbarConfig = {
    fullWidth: false,
    navbarItems: [
        {
            id: "dashboard",
            translationKey: "menuItem.dashboard",
            redirectTo: {
                path: "dashboard"
            }
        },
        {
            id: "challenges",
            translationKey: "menuItem.challenges",
            redirectTo: { path: "challenges" }
        },
        {
            id: "news",
            translationKey: "menuItem.news",
            redirectTo: { path: "news" }
        },
        {
            id: "events",
            translationKey: "menuItem.events",
            redirectTo: { path: "events" }
        },
        {
            id: "webshop",
            translationKey: "menuItem.webshop",
            redirectTo: {
                path: "webshop"
            }
        },
        {
            id: "gallery",
            translationKey: "menuItem.gallery",
            redirectTo: { path: "gallery" }
        },
        {
            id: "about",
            translationKey: "menuItem.about",
            redirectTo: { path: "about" }
        }
    ]
};