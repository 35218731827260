import { ProfileConfig } from "@energylab/shared-components/constants/config/profileConfig";
import { UpdateProfileVariables } from "@energylab/shared-components/models/graphql/types";
import { profileStructure } from "../profileStructure";
import { routeConfig } from "./routeConfig";

export const profileConfig: ProfileConfig = {
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate",
        "sex",
        "department",
        "locale",
        "country",
        "nationality"
    ],
    structure: profileStructure,
    formToValues: (values, originalValues): UpdateProfileVariables => {
        if (!originalValues) {
            throw Error("Can not update profile when not logged in");
        }
        const { locale,givenName,familyName, ...otherValues} = values
        return {
            profile: {
                ...originalValues,
                locale: String(locale) || routeConfig.defaultLocale,
                givenName: String(givenName),
                familyName: String(familyName),
                profile: {
                    ...originalValues.profile,
                    ...otherValues,
                    country: values.country,
                    nationality: values.nationality,
                    birthDate: values.birthDate,
                    sex: values.sex,
                    department: values.department && values.department[0],
                    entity: values.department && values.department[1]
                },
                privacy: {
                    ...originalValues.privacy
                }
            }
        };
    },
    valuesToForm: (values) => {
        if (!values) {
            return {};
        }

        const profile = values.profile || {};

        return {
            ...profile,
            country: profile.country,
            givenName: values.givenName,
            familyName: values.familyName,
            birthDate: profile.birthDate,
            sex: profile.sex,
            email: values.email,
            department: [values.profile.department, values.profile.entity],
            locale: values.locale,
        };
    }
};
