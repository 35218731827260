export interface Department {
    value: string;
    label: string;
    children?: Department[];
}

export const departments: Department[] = [
    {
        value: "Agri Horti",
        label: "Agri Horti",
        children: [
            { value: "Alliance", label: "Alliance" },
            { value: "Aveve", label: "Aveve" },
            { value: "Avenir Agro", label: "Avenir Agro" },
            { value: "Belcrop", label: "Belcrop" },
            { value: "Benfried", label: "Benfried" },
            { value: "Certiplant", label: "Certiplant" },
            { value: "Cofabel", label: "Cofabel" },
            { value: "Hermoo", label: "Hermoo" },
            { value: "Hortiplan", label: "Hortiplan" },
            { value: "Huntjes", label: "Huntjes" },
            { value: "Pomagro", label: "Pomagro" },
            { value: "Pregras", label: "Pregras" },
            { value: "Sanac", label: "Sanac" },
            { value: "Scoriethom", label: "Scoriethom" },
            { value: "Servagri", label: "Servagri" },
            { value: "Vaesken", label: "Vaesken" },
            { value: "Van der Hoeven", label: "Van der Hoeven" },
            { value: "Walagri", label: "Walagri" }
        ]
    },
    {
        value: "Animal Nutrition",
        label: "Animal Nutrition",
        children: [
            { value: "Arie Blok", label: "Arie Blok" },
            { value: "Agro Logic", label: "Algemeen" },
            { value: "Aveve", label: "Aveve" },
            { value: "Equifirst", label: "Equifirst" },
            { value: "Global Feed Mill", label: "Global Feed Mill" },
            { value: "HobbyFirst", label: "HobbyFirst" },
            { value: "Lannoo", label: "Lannoo" },
            { value: "Mifuma", label: "Mifuma" },
            { value: "Natural Granen", label: "Natural Granen" },
            { value: "Palital", label: "Palital" },
            { value: "Proxani", label: "Proxani" },
            { value: "SABE", label: "SABE" },
            { value: "Spoormans", label: "Spoormans" }
        ]
    },
    {
        value: "Arvesta HQ",
        label: "Arvesta HQ",
        children: [
            { value: "Ex com", label: "Ex com" },
            { value: "IT", label: "IT" },
            { value: "HR / Comms / receptie", label: "HR / Comms / receptie" },
            { value: "Environment, health & safety (EHS)", label: "Environment, health & safety (EHS)" },
            { value: "Real Estate", label: "Real Estate" },
            { value: "Marketing & Commercial excellence", label: "Marketing & Commercial excellence" },
            { value: "Shared Services Center", label: "Shared Services Center" },
            { value: "Treasury & credit management", label: "Treasury & credit management" },
            { value: "Internal control & risk management", label: "Internal control & risk management" },
            { value: "Digital", label: "Digital" }
        ]
    },
    {
        value: "Retail",
        label: "Retail",
        children: [
            { value: "Aveve-winkels (winkelpersoneel)", label: "Aveve-winkels (winkelpersoneel)" },
            { value: "Bloem", label: "Bloem" },
            { value: "Eurotuin (winkelpersoneel)", label: "Eurotuin (winkelpersoneel)" },
            { value: "Sales & Marketing", label: "Sales & Marketing" },
            { value: "Logistiek Centrum Wilsele", label: "Logistiek Centrum Wilsele" }
        ]
    }
];
